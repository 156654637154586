var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { colorCodes } from '../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
import { Outlet, useNavigate } from 'react-router-dom';
import { aboutInfoCenterStyle } from './InfoCenter.styled';
import Tabs from '../../../../tandym-web-common/src/components/Tabs';
import ReactGA from 'react-ga4';
import { useClientAppSelector } from '../../store/hook';
var InfoCenter = function () {
    var navigate = useNavigate();
    var entitlementsData = useClientAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var _a = useState([]), tabs = _a[0], setTabs = _a[1];
    useEffect(function () {
        if (entitlementsData.entitlementDto) {
            setTabs([
                {
                    path: '/infoCenter/aboutTandym',
                    label: 'ABOUT TANDYM',
                    show: entitlementsData.entitlementDto.clientInfoCenterJson
                        .aboutTandym,
                },
                {
                    path: '/infoCenter/ourTalent',
                    label: 'OUR TALENT',
                    show: entitlementsData.entitlementDto.clientInfoCenterJson.ourTalent,
                },
                {
                    path: '/infoCenter/timeApprovalProcedures',
                    label: 'TIME APPROVAL PROCEDURES',
                    show: entitlementsData.entitlementDto.clientInfoCenterJson
                        .timeApprovalProcedure,
                },
                {
                    path: '/infoCenter/contact',
                    label: 'CONTACT',
                    show: entitlementsData.entitlementDto.clientInfoCenterJson.contact,
                },
            ]);
        }
    }, [entitlementsData]);
    var handleClickedTab = function (label) {
        ReactGA.event({
            category: 'Client',
            action: 'Click',
            label: "Client Info Center ".concat(label, " tab"),
        });
    };
    return (_jsx(Grid, __assign({ container: true }, { children: _jsxs(Box, __assign({ sx: {
                width: '100%',
                p: 4,
                background: colorCodes.backgroundColors.white,
            } }, { children: [_jsx(Box, { children: _jsxs(Box, __assign({ pr: 2, sx: aboutInfoCenterStyle.containerBox }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex' } }, { children: [_jsx(TPSvgIcon, { resourceName: 'small-vertical-line-icon' }), _jsx(Typography, __assign({ sx: aboutInfoCenterStyle.infoCenterTitle }, { children: "Information Center" }))] })), _jsxs(Box, __assign({ sx: { display: 'flex', gap: 1, justifyItems: 'center' } }, { children: [_jsx(Typography, __assign({ sx: aboutInfoCenterStyle.needHelpText }, { children: "Need Help?" })), _jsx(Typography, __assign({ sx: aboutInfoCenterStyle.faqText, onClick: function () { return navigate('/faqs'); }, id: 'client-faq', className: 'client-portal-button-click' }, { children: "FAQ" }))] }))] })) }), _jsx(Box, __assign({ mt: 3, sx: { borderBottom: '1px solid #e9e9e9' } }, { children: _jsx(Tabs, { headerTabs: tabs, tabSize: 'small', tabClick: handleClickedTab, portal: 'client' }) })), _jsx(Outlet, {})] })) })));
};
export default InfoCenter;
