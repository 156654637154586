var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import TPSvgIcon from '../../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { DatePicker } from '@mui/x-date-pickers-pro';
import RecruitmentTimeline from '../../../components/RecruitmentTimeline/RecruitmentTimeline';
import RecruitmentTimelineSteps from '../../../components/RecruitmentTimelineSteps/RecruitmentTimelineSteps';
import { TPStyledGrid } from '../OperationalMetrics/OperationalMetrics.styled';
import TPProgressModal from '../../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import TPButton from '../../../../../tandym-web-common/src/shared/components/button/TPButton';
import { useAPI } from '../../../../../tandym-web-common/src/shared/services/api/API';
import { TANDYM_CONSTANTS } from '../../../shared/Constants';
import { useClientAppSelector } from '../../../store/hook';
import dayjs from 'dayjs';
import TPDropdown from '../../../../../tandym-web-common/src/shared/components/dropdown/TPDropdown';
import TPToast from '../../../../../tandym-web-common/src/shared/components/toast/TPToast';
import NoDataFound from '../../../components/NoDataFound/NoDataFound';
import { isMobile } from '../../../../../tandym-web-common/src/shared/utilities/isMobileEntitlementFlag';
import ReactGA from 'react-ga4';
var DeliveryBenchMark = function (_a) {
    var clientName = _a.clientName, setRecruitmentDrillDownOpen = _a.setRecruitmentDrillDownOpen, setRecruitmentDrillDownData = _a.setRecruitmentDrillDownData, setFunctionalGroup = _a.setFunctionalGroup, setOperationalMatrixStartDate = _a.setOperationalMatrixStartDate, setOperationalMatrixEndDate = _a.setOperationalMatrixEndDate;
    var deliveryBenchmarkTestId = 'delivery-benchmark';
    var _b = useState([]), functionalGroupList = _b[0], setFunctionalGroupList = _b[1];
    var _c = useState(''), selectedOptions = _c[0], setSelectedOptions = _c[1];
    var _d = useState(null), error = _d[0], setError = _d[1];
    var _e = useState(true), isLoading = _e[0], setIsLoading = _e[1];
    var _f = useState(), recruitmentTimelineData = _f[0], setRecruitmentTimelineData = _f[1];
    var _g = useState(null), startDate = _g[0], setStartDate = _g[1];
    var _h = useState(null), endDate = _h[0], setEndDate = _h[1];
    var _j = useState(true), applyButtonDisabled = _j[0], setApplyButtonDisabled = _j[1];
    var _k = useState(true), applySelectBtn = _k[0], setApplySelectBtn = _k[1];
    var _l = useState(''), successMessage = _l[0], setSuccessMessage = _l[1];
    var _m = recruitmentTimelineData || {}, clientDataMetrics = _m.clientDataMetrics, tandymDataMetrics = _m.tandymDataMetrics, recruitmentTimelineStepsMetrics = _m.recruitmentTimelineStepsMetrics;
    var disabledApplyButton = (functionalGroupList !== undefined || selectedOptions !== undefined) &&
        applyButtonDisabled;
    var disableBtn = applySelectBtn && applyButtonDisabled;
    useEffect(function () {
        var timeOut = setTimeout(function () {
            setSuccessMessage('');
        }, 3000);
        return function () { return clearTimeout(timeOut); };
    }, [successMessage]);
    var handleChange = function (event) {
        setApplySelectBtn(false);
        setSelectedOptions(event.target.value);
    };
    var httpPost = useAPI().httpPost;
    var _o = useClientAppSelector(function (state) { return state.userAuth; }), candidateId = _o.candidateId, StartDate = _o.StartDate;
    var fetchDeliveryBenchMark = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
        return __generator(this, function (_y) {
            switch (_y.label) {
                case 0:
                    setIsLoading(true);
                    ReactGA.event({
                        category: 'Client',
                        action: 'Click',
                        label: 'Delivery Benchmark Apply Button',
                    });
                    _y.label = 1;
                case 1:
                    _y.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, httpPost(TANDYM_CONSTANTS.API_URLS.CLIENT_DELIVERY_BENCHMARK_POST, disableBtn
                            ? {}
                            : {
                                functionalGroups: [
                                    selectedOptions && selectedOptions.length > 0
                                        ? selectedOptions
                                        : functionalGroupList && functionalGroupList.length > 0
                                            ? functionalGroupList[0]
                                            : '',
                                ],
                                recruitmentTimelineandRatioDateRange: {
                                    startDate: startDate === null || startDate === void 0 ? void 0 : startDate.format('YYYY-MM-DD'),
                                    endDate: endDate === null || endDate === void 0 ? void 0 : endDate.format('YYYY-MM-DD'),
                                },
                            }, {
                            clientId: candidateId,
                        })];
                case 2:
                    response = _y.sent();
                    if (!response.error) {
                        setRecruitmentTimelineData(response.response.recruitementTimelineZoomIn);
                        disableBtn &&
                            setFunctionalGroupList(((_b = (_a = response.response) === null || _a === void 0 ? void 0 : _a.recruitementTimelineZoomIn) === null || _b === void 0 ? void 0 : _b.functionalGroupList)
                                ? (_d = (_c = response.response) === null || _c === void 0 ? void 0 : _c.recruitementTimelineZoomIn) === null || _d === void 0 ? void 0 : _d.functionalGroupList
                                : []);
                        disableBtn &&
                            setSelectedOptions(((_f = (_e = response.response) === null || _e === void 0 ? void 0 : _e.recruitementTimelineZoomIn) === null || _f === void 0 ? void 0 : _f.functionalGroupList) &&
                                ((_h = (_g = response.response) === null || _g === void 0 ? void 0 : _g.recruitementTimelineZoomIn) === null || _h === void 0 ? void 0 : _h.functionalGroupList.length) > 0
                                ? (_k = (_j = response.response) === null || _j === void 0 ? void 0 : _j.recruitementTimelineZoomIn) === null || _k === void 0 ? void 0 : _k.functionalGroupList[0]
                                : '');
                        // default Date Range
                        disableBtn &&
                            setStartDate(dayjs((_o = (_m = (_l = response.response) === null || _l === void 0 ? void 0 : _l.recruitementTimelineZoomIn) === null || _m === void 0 ? void 0 : _m.startDate) !== null && _o !== void 0 ? _o : null));
                        disableBtn &&
                            setEndDate(dayjs((_r = (_q = (_p = response.response) === null || _p === void 0 ? void 0 : _p.recruitementTimelineZoomIn) === null || _q === void 0 ? void 0 : _q.endDate) !== null && _r !== void 0 ? _r : null));
                        disableBtn &&
                            setOperationalMatrixStartDate &&
                            setOperationalMatrixStartDate(dayjs((_u = (_t = (_s = response.response) === null || _s === void 0 ? void 0 : _s.recruitementTimelineZoomIn) === null || _t === void 0 ? void 0 : _t.startDate) !== null && _u !== void 0 ? _u : null));
                        disableBtn &&
                            setOperationalMatrixEndDate &&
                            setOperationalMatrixEndDate(dayjs((_x = (_w = (_v = response.response) === null || _v === void 0 ? void 0 : _v.recruitementTimelineZoomIn) === null || _w === void 0 ? void 0 : _w.endDate) !== null && _x !== void 0 ? _x : null));
                        // Inside your component or function
                        !disableBtn && response.message && setSuccessMessage(response.message);
                        setFunctionalGroup && setFunctionalGroup(selectedOptions);
                    }
                    else {
                        setError(response.message);
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _y.sent();
                    console.error(error_1, 'error');
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    setApplyButtonDisabled(true);
                    setApplySelectBtn(true);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [isLoading, applyButtonDisabled, selectedOptions, startDate, endDate]);
    // Validate dates
    var validateDates = function (from, to) {
        if (from && to) {
            if (from.isAfter(to)) {
                setError('From date cannot be after To date.');
                setApplyButtonDisabled(true);
            }
            else {
                setError(null);
                setApplyButtonDisabled(false);
            }
        }
    };
    var handleStartDate = function (date) {
        setStartDate(date);
        setOperationalMatrixStartDate && setOperationalMatrixStartDate(date);
        validateDates(date, endDate);
    };
    var handleEndDate = function (date) {
        setEndDate(date);
        setOperationalMatrixEndDate && setOperationalMatrixEndDate(date);
        validateDates(startDate, date);
    };
    useEffect(function () {
        disabledApplyButton && fetchDeliveryBenchMark();
    }, [candidateId]);
    var functionalGroupsList = functionalGroupList
        ? functionalGroupList === null || functionalGroupList === void 0 ? void 0 : functionalGroupList.map(function (group) { return ({
            label: group,
            value: group,
        }); })
        : [];
    var defaultValue = selectedOptions && selectedOptions.length > 0
        ? selectedOptions
        : functionalGroupList && functionalGroupList.length > 0
            ? functionalGroupList[0]
            : '';
    return (_jsxs(TPStyledGrid, { children: [_jsx(TPProgressModal, { isLoading: isLoading }), _jsxs(Grid, __assign({ container: true, alignItems: 'center' }, { children: [_jsx(TPToast, { message: successMessage, open: successMessage !== '' ? true : false, vertical: 'top', horizontal: 'center', type: 'success' }), _jsxs(Grid, __assign({ container: true, mt: 2, bgcolor: '#fff' }, { children: [_jsx(Typography, __assign({ id: "".concat(deliveryBenchmarkTestId, "-Jobs-closed-date"), "data-testid": "".concat(deliveryBenchmarkTestId, "-jobs-closed-date"), pl: 2, py: 2 }, { children: "Change and apply this functional skill to compare :" })), _jsx(Grid, __assign({ item: true, display: 'flex', p: 2 }, { children: _jsx(TPDropdown, { name: 'deliveryBenchmark', options: functionalGroupsList, id: "".concat(deliveryBenchmarkTestId, "-dropdown"), "data-testid": "".concat(deliveryBenchmarkTestId, "-dropdown"), defaultSelectedOption: defaultValue, clearStatus: false, onChange: handleChange, placeholder: 'Select Group', sx: { minWidth: isMobile() ? '100%' : '250px' } }) })), _jsx(Typography, __assign({ id: "".concat(deliveryBenchmarkTestId, "-Jobs-closed-date"), "data-testid": "".concat(deliveryBenchmarkTestId, "-jobs-closed-date"), sx: { p: 2 } }, { children: "Jobs Closed Date :" })), _jsxs(Grid, __assign({ item: true, sx: {
                                    display: 'flex',
                                    flexDirection: isMobile() ? 'row' : { xs: 'column', sm: 'row' },
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    mt: { xs: 2, sm: 0 },
                                    px: { xs: 1, sm: 0 },
                                } }, { children: [_jsx(DatePicker, { disableFuture: true, value: startDate, minDate: dayjs(StartDate), views: ['year', 'month', 'day'], format: 'MM-DD-YYYY', onChange: handleStartDate, sx: { width: isMobile() ? '100%' : '28%' }, slots: {
                                            openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: 'date-picker-icon', id: "".concat(deliveryBenchmarkTestId, "-start-date-icon"), "data-testid": "".concat(deliveryBenchmarkTestId, "-start-date-icon") })); },
                                        } }), _jsx(Typography, __assign({ sx: { p: 2 } }, { children: "-" })), _jsx(DatePicker, { disableFuture: true, value: endDate, views: ['year', 'month', 'day'], format: 'MM-DD-YYYY', onChange: handleEndDate, sx: { width: isMobile() ? '100%' : '28%' }, slots: {
                                            openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: 'date-picker-icon', id: "".concat(deliveryBenchmarkTestId, "-end-date-icon"), "data-testid": "".concat(deliveryBenchmarkTestId, "-end-date-icon") })); },
                                        } })] })), _jsx(Box, __assign({ p: 1 }, { children: _jsx(TPButton, __assign({ id: "".concat(deliveryBenchmarkTestId, "-apply-button"), "data-testid": "".concat(deliveryBenchmarkTestId, "-apply-button"), className: 'client-portal-button-click', disabled: disableBtn, onClick: fetchDeliveryBenchMark }, { children: "Apply" })) })), _jsx(Grid, __assign({ container: true, spacing: 1 }, { children: _jsx(Grid, __assign({ item: true, xs: 12, md: 12, sx: {
                                        pr: 2,
                                        paddingTop: '0px !important',
                                    } }, { children: _jsxs(Box, __assign({ sx: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            flexWrap: 'wrap',
                                        } }, { children: [_jsx(Typography, __assign({ sx: { p: { xs: 1, md: 2 } } }, { children: "Legend:" })), _jsx(Box, { sx: {
                                                    height: '15px',
                                                    width: '30px',
                                                    background: '#2b6fea',
                                                } }), _jsx(Typography, __assign({ sx: { p: { xs: 1, md: 1 } } }, { children: "Tandym responsibility" })), _jsx(Box, { sx: {
                                                    marginLeft: '3rem',
                                                    height: '15px',
                                                    width: '30px',
                                                    background: '#a6a6a6',
                                                } }), _jsx(Typography, __assign({ sx: { p: { xs: 1, md: 1 } } }, { children: "Client responsibility" })), _jsx(Typography, __assign({ sx: { marginLeft: '3rem', p: { xs: 1, md: 1 } } }, { children: "Note: 0 AVG DAYS = Same Day, N/A = No Data" }))] })) })) })), _jsx(Grid, __assign({ container: true, pl: 2, pb: 1 }, { children: error && startDate !== null && endDate !== null && (_jsx(Typography, __assign({ color: 'error', id: "".concat(deliveryBenchmarkTestId, "-error-message"), "data-testid": "".concat(deliveryBenchmarkTestId, "-error-message"), className: 'error_message' }, { children: error }))) }))] })), _jsxs(Grid, __assign({ container: true, spacing: 2, py: 2, className: 'recruitment-timeline-container-grid' }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Box, __assign({ sx: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            height: '50px',
                                            backgroundColor: '#fff',
                                            padding: 2,
                                        } }, { children: _jsx(Typography, __assign({ id: "".concat(deliveryBenchmarkTestId, "-recruitment-timeline-title"), "data-testid": "".concat(deliveryBenchmarkTestId, "-recruitment-timeline-title"), className: 'recruitment-timeline-title', variant: 'h6', component: 'h2' }, { children: "Recruitment Timeline" })) })), _jsx(Box, __assign({ sx: {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: '#fff',
                                            padding: 1,
                                        } }, { children: _jsx(RecruitmentTimeline, { recruitmentTimelineData: clientDataMetrics !== null && clientDataMetrics !== void 0 ? clientDataMetrics : [], setRecruitmentDrillDownOpen: setRecruitmentDrillDownOpen, setRecruitmentDrillDownData: setRecruitmentDrillDownData, selectedFunctionalGroup: selectedOptions, setFunctionalGroup: setFunctionalGroup !== null && setFunctionalGroup !== void 0 ? setFunctionalGroup : '', isLoading: isLoading, tabName: 'Delivery-Benchmark' }) })), _jsx(Box, __assign({ sx: {
                                            display: 'flex',
                                            backgroundColor: '#fff',
                                            padding: 2,
                                        } }, { children: _jsx(Typography, { children: "Click the values on the bar graph to drill down except Total." }) }))] })), _jsxs(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Box, __assign({ sx: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            height: '50px',
                                            backgroundColor: '#fff',
                                            padding: 2,
                                        } }, { children: _jsx(Typography, __assign({ id: "".concat(deliveryBenchmarkTestId, "-tandym-average-title"), "data-testid": "".concat(deliveryBenchmarkTestId, "-tandym-average-title"), variant: 'h6', component: 'h2' }, { children: "Tandym Average" })) })), _jsx(Box, __assign({ sx: {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: '#fff',
                                            padding: 1,
                                        } }, { children: _jsx(RecruitmentTimeline, { title: 'Tandym Average', recruitmentTimelineData: tandymDataMetrics !== null && tandymDataMetrics !== void 0 ? tandymDataMetrics : [], isLoading: isLoading, tabName: 'Delivery-Benchmark' }) }))] }))] })), _jsxs(Grid, __assign({ item: true, xs: 12, md: 12 }, { children: [_jsx(Box, __assign({ sx: {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#000',
                                    padding: 2,
                                } }, { children: _jsxs(Box, __assign({ display: 'flex' }, { children: [_jsx(Typography, __assign({ id: "".concat(deliveryBenchmarkTestId, "-recruitment-timeline-steps-title"), "data-testid": "".concat(deliveryBenchmarkTestId, "-recruitment-timeline-steps-title"), variant: 'h5', component: 'h2' }, { children: "Recruitment Timeline" })), _jsx(Typography, __assign({ variant: 'h5', component: 'h2', px: 1 }, { children: "-" })), _jsx(Typography, __assign({ variant: 'h5', component: 'h2' }, { children: "Average Number of days for each recruitment step" }))] })) })), _jsx(Box, __assign({ mb: 5 }, { children: recruitmentTimelineStepsMetrics &&
                                    recruitmentTimelineStepsMetrics.deltaFromTandymAvg !== null &&
                                    recruitmentTimelineStepsMetrics.kpi !== null &&
                                    recruitmentTimelineStepsMetrics.selectedClient !== null &&
                                    recruitmentTimelineStepsMetrics.tandymGTMAvg !== null ? (_jsx(RecruitmentTimelineSteps, { clientName: clientName, recruitmentTimelineSteps: recruitmentTimelineStepsMetrics })) : (_jsx(NoDataFound, { isLoading: isLoading })) }))] }))] }))] }));
};
export default DeliveryBenchMark;
