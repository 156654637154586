var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import TPButton from '../../../../tandym-web-common/src/shared/components/button/TPButton';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { aboutTandymContent } from './aboutTandymContent';
import { aboutInfoCenterStyle } from './InfoCenter.styled';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var AboutTandym = function () {
    return (_jsx(Box, __assign({ mt: 3 }, { children: _jsx(Grid, __assign({ container: true, spacing: 2 }, { children: aboutTandymContent.map(function (content, index) { return (_jsxs(Grid, __assign({ item: true, xs: 12, sm: 4, sx: {
                    display: 'flex',
                    flexDirection: 'column',
                } }, { children: [_jsx("img", { src: content.image, alt: content.title, style: { width: '100%', display: 'block' } }), _jsx(Box, __assign({ p: 4, sx: aboutInfoCenterStyle.subContainerBox }, { children: _jsxs(Grid, __assign({ container: true, sx: { height: '55%' } }, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ pb: 3, sx: aboutInfoCenterStyle.backgroundImgTitle }, { children: content.title })) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ sx: aboutInfoCenterStyle.backgroundImgDescription }, { children: content.description })) })), _jsx(Grid, __assign({ item: true, xs: 12, sx: {
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        mt: content.title === 'Who We Are'
                                            ? '14%'
                                            : content.title === 'Specialized Solutions'
                                                ? '20%'
                                                : content.title === 'Recruitment Expertise'
                                                    ? '20%'
                                                    : 0,
                                    } }, { children: _jsxs(TPButton, __assign({ color: 'primary', onClick: content.onClickBtn, id: "info-center-about-tandym-".concat(content.buttonLabel), className: 'client-portal-button-click', sx: {
                                            gap: 1,
                                            justifyContent: 'center',
                                            fontSize: fontSizeDynamic(14),
                                        } }, { children: [content.buttonLabel, _jsx(TPSvgIcon, { resourceName: 'arrow-left-alt' })] })) }))] })) }))] }), index + content.title)); }) })) })));
};
export default AboutTandym;
