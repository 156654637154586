var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { colorCodes } from '../../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
import TPSvgIcon from '../../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import RecruitmentTimeline from '../../../components/RecruitmentTimeline/RecruitmentTimeline';
import ContractorsBilling from '../../../components/ContractorBilling/ContractorsBilling';
import PlacedPositions from '../../../components/PlacedPositions/PlacedPositions';
import RecruitmentRatios from '../../../components/RecruitmentRatios/RecruitmentRatios';
import { TPStyledGrid } from './OperationalMetrics.styled';
import dayjs from 'dayjs';
import { TANDYM_CONSTANTS } from '../../../shared/Constants';
import TPProgressModal from '../../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import { useAPI } from '../../../../../tandym-web-common/src/shared/services/api/API';
import { useClientAppSelector } from '../../../store/hook';
import TPButton from '../../../../../tandym-web-common/src/shared/components/button/TPButton';
import TPToast from '../../../../../tandym-web-common/src/shared/components/toast/TPToast';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { isMobile } from '../../../../../tandym-web-common/src/shared/utilities/isMobileEntitlementFlag';
import CustomModal from '../../../../../tandym-web-common/src/shared/components/modal/TPCustomModal';
import InfoModal from './InfoModal';
import { contractorsOnBilling, placedPositions, recruitmentRatios, recruitmentTimeline, } from '../../../__mocks__/OperationalMetricsInfoModal.mock';
import ReactGA from 'react-ga4';
var OperationalMetrics = function (_a) {
    var setOpenDrillDown = _a.setOpenDrillDown, setOperationaldrillDownData = _a.setOperationaldrillDownData, setRecruitmentDrillDownOpen = _a.setRecruitmentDrillDownOpen, setRecruitmentDrillDownData = _a.setRecruitmentDrillDownData, setFunctionalGroup = _a.setFunctionalGroup, setOperationalMatrixStartDate = _a.setOperationalMatrixStartDate, setOperationalMatrixEndDate = _a.setOperationalMatrixEndDate;
    var _b = useState([]), recruitmentTimelineData = _b[0], setRecruitmentTimelineData = _b[1];
    var _c = useState(null), contractorOnBiilingData = _c[0], setContractorOnBiilingData = _c[1];
    var _d = useState(null), PlacedPositionsData = _d[0], setPlacedPositionsData = _d[1];
    var _e = useState(null), recruitmentRatiosData = _e[0], setRecruitmentRatiosData = _e[1];
    var httpPost = useAPI().httpPost;
    var _f = useState(false), isLoading = _f[0], setIsLoading = _f[1];
    var _g = useClientAppSelector(function (state) { return state.userAuth; }), candidateId = _g.candidateId, isLoggedIn = _g.isLoggedIn, StartDate = _g.StartDate;
    var _h = useState(null), startDate = _h[0], setStartDate = _h[1];
    var _j = useState(null), endDate = _j[0], setEndDate = _j[1];
    var _k = useState(true), applyButtonDisabled = _k[0], setApplyButtonDisabled = _k[1];
    var _l = useState(null), error = _l[0], setError = _l[1];
    var _m = useState(''), successMessage = _m[0], setSuccessMessage = _m[1];
    var _o = useState(false), showRR = _o[0], setShowRR = _o[1];
    var _p = useState(false), showCB = _p[0], setShowCB = _p[1];
    var _q = useState(false), openInfoModal = _q[0], setOpenInfoModal = _q[1];
    var _r = useState(null), infoModalData = _r[0], setInfoModalData = _r[1];
    var _s = useState(''), modalHeading = _s[0], setModalHeading = _s[1];
    useEffect(function () {
        var timeOut = setTimeout(function () {
            setSuccessMessage('');
        }, 3000);
        return function () { return clearTimeout(timeOut); };
    }, [successMessage]);
    var fetchOperationalMetricsData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, operationalMetricsData, error_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    ReactGA.event({
                        category: 'Client',
                        action: 'Click',
                        label: 'Operational Metrics Apply Button',
                    });
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, 4, 5]);
                    setIsLoading(true);
                    return [4 /*yield*/, httpPost(TANDYM_CONSTANTS.API_URLS.CLIENT_OPERATIONAL_METRICS_GET, startDate !== null || endDate !== null
                            ? {
                                recruitmentTimelineandRatioDateRange: {
                                    startDate: startDate === null || startDate === void 0 ? void 0 : startDate.format('YYYY-MM-DD'),
                                    endDate: endDate === null || endDate === void 0 ? void 0 : endDate.format('YYYY-MM-DD'),
                                },
                            }
                            : {}, {
                            clientId: candidateId,
                        })];
                case 2:
                    response = _e.sent();
                    if (!response.error) {
                        operationalMetricsData = response === null || response === void 0 ? void 0 : response.response;
                        setRecruitmentRatiosData(operationalMetricsData === null || operationalMetricsData === void 0 ? void 0 : operationalMetricsData.recruitmentRatioMetrics);
                        setRecruitmentTimelineData(operationalMetricsData === null || operationalMetricsData === void 0 ? void 0 : operationalMetricsData.recruitmentTimelineMetrics);
                        if (applyButtonDisabled) {
                            setContractorOnBiilingData(operationalMetricsData === null || operationalMetricsData === void 0 ? void 0 : operationalMetricsData.contractorsOnBillingMetrics);
                            setPlacedPositionsData(operationalMetricsData === null || operationalMetricsData === void 0 ? void 0 : operationalMetricsData.placedPositionsMetrics);
                        }
                        setSuccessMessage(!applyButtonDisabled ? response.message : '');
                        setStartDate(dayjs((_a = response === null || response === void 0 ? void 0 : response.response) === null || _a === void 0 ? void 0 : _a.startDate));
                        setEndDate(dayjs((_b = response === null || response === void 0 ? void 0 : response.response) === null || _b === void 0 ? void 0 : _b.endDate));
                        setOperationalMatrixStartDate &&
                            setOperationalMatrixStartDate(dayjs((_c = response === null || response === void 0 ? void 0 : response.response) === null || _c === void 0 ? void 0 : _c.startDate));
                        setOperationalMatrixEndDate &&
                            setOperationalMatrixEndDate(dayjs((_d = response === null || response === void 0 ? void 0 : response.response) === null || _d === void 0 ? void 0 : _d.endDate));
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _e.sent();
                    console.error('Error fetching Operational Metrics Data:', error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setApplyButtonDisabled(true);
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        isLoggedIn && fetchOperationalMetricsData();
    }, [isLoggedIn]);
    var handleStartDateChange = function (date) {
        setStartDate(date);
        setOperationalMatrixStartDate && setOperationalMatrixStartDate(date);
        validateDates(date, endDate);
    };
    var handleEndDateChange = function (date) {
        setEndDate(date);
        setOperationalMatrixEndDate && setOperationalMatrixEndDate(date);
        validateDates(startDate, date);
    };
    // Validate dates
    var validateDates = function (from, to) {
        if (from && to) {
            if (from.isAfter(to)) {
                setError('From date cannot be after To date.');
                setApplyButtonDisabled(true);
            }
            else {
                setError(null);
                setApplyButtonDisabled(false);
            }
        }
    };
    var handleInfoModal = function (val) {
        setOpenInfoModal(true);
        if (val === 'recruitment-ratios') {
            setInfoModalData(recruitmentRatios);
            setModalHeading('Recruitment Ratios');
        }
        if (val === 'recruitment-timeline') {
            setInfoModalData(recruitmentTimeline);
            setModalHeading('Recruitment Timeline');
        }
        if (val === 'contractors-on-billing') {
            setInfoModalData(contractorsOnBilling);
            setModalHeading('Contractors on Billing');
        }
        if (val === 'placed-positions') {
            setInfoModalData(placedPositions);
            setModalHeading('Placed Positions');
        }
    };
    return (_jsxs(TPStyledGrid, { children: [_jsx(TPProgressModal, { isLoading: isLoading }), _jsx(TPToast, { message: successMessage, open: successMessage !== '' ? true : false, vertical: 'top', horizontal: 'center', type: 'success' }), _jsxs(Grid, __assign({ container: true, spacing: 2, mb: 6, mt: 2, className: 'operational-matrix-grid-container' }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, md: 6, sx: {
                            borderRight: { xs: 'none', md: '1px solid black' },
                            pr: { xs: 0, md: 2 },
                            paddingTop: '0px !important',
                        } }, { children: _jsxs(Grid, __assign({ item: true, sx: {
                                p: 2,
                                borderBottom: { xs: '1px solid #c9cbd1', md: 'none' },
                                backgroundColor: colorCodes.backgroundColors.white,
                            } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: [(isMobile() ? showRR : true) && (_jsxs(Box, __assign({ sx: {
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: { xs: 'center', sm: 'flex-start' },
                                                flexWrap: 'wrap',
                                                mb: '20px',
                                                order: { xs: 2, md: 1 },
                                            } }, { children: [_jsx(Typography, __assign({ sx: {
                                                        p: { xs: 1, md: 2 },
                                                        width: { xs: '100%', sm: 'auto' },
                                                    } }, { children: "Jobs Closed Date:" })), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { disableFuture: true, minDate: dayjs(StartDate), onChange: handleStartDateChange, value: startDate, sx: { width: { xs: '46%', sm: '22%' } }, slotProps: {
                                                            textField: {
                                                                inputProps: {
                                                                    id: 'start-date-picker-1',
                                                                    'data-testid': 'start-date-picker-1',
                                                                },
                                                            },
                                                        }, slots: {
                                                            openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: 'date-picker-icon', id: 'date-picker-icon', "data-testid": 'date-picker-icon' })); },
                                                        } }) })), _jsx(Typography, __assign({ sx: { p: { xs: 0, md: 1, lg: 2 } } }, { children: "-" })), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { disableFuture: true, onChange: handleEndDateChange, value: endDate, sx: { width: { xs: '46%', sm: '22%' } }, slotProps: {
                                                            textField: {
                                                                inputProps: {
                                                                    id: 'end-date-picker-1',
                                                                    'data-testid': 'end-date-picker-1',
                                                                },
                                                            },
                                                        }, slots: {
                                                            openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: 'date-picker-icon', id: 'date-picker-icon', "data-testid": 'date-picker-icon' })); },
                                                        } }) })), _jsx(TPButton, __assign({ sx: { ml: { xs: 0, sm: 2, md: 4 }, mt: { xs: 2, md: 0 } }, color: 'primary', disabled: applyButtonDisabled, onClick: fetchOperationalMetricsData, className: 'client-portal-button-click', id: 'operational-metrics-apply-filter-button', "data-testid": 'operational-metrics-apply-filter-button' }, { children: "Apply" })), error && (_jsx(Typography, __assign({ color: 'error', id: 'error-message', "data-testid": 'error-message', className: 'error_message' }, { children: error })))] }))), _jsxs(Box, __assign({ sx: {
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                flexWrap: 'wrap',
                                                mb: '10px',
                                                order: { xs: 1, md: 2 },
                                            } }, { children: [_jsxs(Typography, __assign({ id: 'recruitment-ratios-title', "data-testid": 'recruitment-ratios-title', variant: 'h6', component: 'h2', sx: {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '10px',
                                                        width: { xs: 'auto', md: '100%' },
                                                    } }, { children: ["Recruitment Ratios", _jsx(Box, __assign({ id: 'info-icon-1', "data-testid": 'info-icon-1', sx: { cursor: 'pointer', marginLeft: 'auto' }, onClick: function () { return handleInfoModal('recruitment-ratios'); } }, { children: _jsx(TPSvgIcon, { resourceName: 'info-icon', id: 'info-icon', "data-testid": 'info-icon' }) }))] })), isMobile() && (_jsx(Box, { children: _jsx(IconButton, __assign({ onClick: function () { return setShowRR(!showRR); } }, { children: _jsx(TPSvgIcon, { id: 'recruitment-ratios-caret-icon', "data-testid": 'recruitment-ratios-caret-icon', resourceName: showRR
                                                                ? 'up-arrow-strong-icon'
                                                                : 'down-arrow-strong-icon', width: 24, height: 24 }) })) }))] })), (isMobile() ? showRR : true) && (_jsx(Box, __assign({ sx: { order: { xs: 3, md: 3 } } }, { children: _jsx(RecruitmentRatios, { recruitmentRatiosData: recruitmentRatiosData, isLoading: isLoading }) })))] })), (isMobile() ? showRR : true) && (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ className: 'recruitment-timeline-chart-top-grid', sx: {
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                mt: 8,
                                            } }, { children: [_jsx(Typography, __assign({ id: 'recruitment-timeline-title', "data-testid": 'recruitment-timeline-title', className: 'recruitment-timeline-title', variant: 'h6', component: 'h2', sx: {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '10px',
                                                    } }, { children: "Recruitment Timeline" })), _jsx(Box, __assign({ id: 'info-icon-2', "data-testid": 'info-icon-2', sx: { cursor: 'pointer' }, onClick: function () { return handleInfoModal('recruitment-timeline'); } }, { children: _jsx(TPSvgIcon, { resourceName: 'info-icon', id: 'info-icon', "data-testid": 'info-icon' }) }))] })), _jsx(Typography, __assign({ sx: { pt: 2 } }, { children: "Note: 0 AVG DAYS = Same Day, N/A = No Data" })), _jsx(Box, __assign({ mt: 4, className: 'recruitment-timeline-chart-bar-grid' }, { children: _jsx(RecruitmentTimeline, { recruitmentTimelineData: recruitmentTimelineData, setRecruitmentDrillDownOpen: setRecruitmentDrillDownOpen, setRecruitmentDrillDownData: setRecruitmentDrillDownData, setFunctionalGroup: setFunctionalGroup, isLoading: isLoading, tabName: 'Operational-Metrics' }) })), recruitmentTimelineData && (_jsx(Grid, __assign({ container: true, spacing: 1 }, { children: _jsxs(Grid, __assign({ item: true, xs: 12, md: 12, sx: {
                                                    pr: 2,
                                                    paddingTop: '0px !important',
                                                } }, { children: [_jsxs(Box, __assign({ sx: {
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            flexWrap: 'wrap',
                                                            mb: '20px',
                                                        } }, { children: [_jsx(Typography, __assign({ sx: { p: { xs: 1, md: 2 } } }, { children: "Legend:" })), _jsx(Box, { sx: {
                                                                    height: '15px',
                                                                    width: '30px',
                                                                    background: '#2b6fea',
                                                                } }), _jsx(Typography, __assign({ sx: { p: { xs: 1, md: 1 } } }, { children: "Tandym responsibility" })), _jsx(Box, { sx: {
                                                                    marginLeft: '3rem',
                                                                    height: '15px',
                                                                    width: '30px',
                                                                    background: '#a6a6a6',
                                                                } }), _jsx(Typography, __assign({ sx: { p: { xs: 1, md: 1 } } }, { children: "Client responsibility" }))] })), _jsx(Typography, __assign({ sx: { pl: 2 } }, { children: "Click the values on the bar graph to drill down except Total." }))] })) })))] }))] })) })), _jsxs(Grid, __assign({ item: true, xs: 12, md: 6, sx: { paddingTop: '0px !important' } }, { children: [_jsx(Grid, __assign({ item: true, sx: {
                                    p: 2,
                                    backgroundColor: colorCodes.backgroundColors.white,
                                } }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: [(isMobile() ? showCB : true) && (_jsx(Box, __assign({ sx: {
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: '50px',
                                                flexWrap: 'wrap',
                                                order: { xs: 2, md: 1 },
                                            } }, { children: _jsx("span", { children: "12 Months Trend" }) }))), _jsxs(Box, __assign({ sx: {
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: '50px',
                                                flexWrap: 'wrap',
                                                justifyContent: {
                                                    xs: 'start',
                                                    md: 'flex-start',
                                                },
                                                order: { xs: 1, md: 2 },
                                            } }, { children: [_jsxs(Typography, __assign({ id: 'contractors-on-billing-title', "data-testid": 'contractors-on-billing-title', variant: 'h6', component: 'h2', sx: {
                                                        mb: 0,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        alignSelf: 'start',
                                                        flexWrap: 'wrap',
                                                        width: { xs: '80%', md: '100%' },
                                                    } }, { children: ["Contractors on Billing", _jsx(Typography, __assign({ sx: {
                                                                ml: { xs: 0, sm: 1 },
                                                            } }, { children: "(12 Months Trend)" })), _jsx(Box, __assign({ id: 'info-icon-3', "data-testid": 'info-icon-3', sx: {
                                                                cursor: 'pointer',
                                                                marginLeft: { xs: '0', md: 'auto' },
                                                            }, onClick: function () { return handleInfoModal('contractors-on-billing'); } }, { children: _jsx(TPSvgIcon, { resourceName: 'info-icon', id: 'info-icon', "data-testid": 'info-icon' }) }))] })), isMobile() && (_jsx(Box, __assign({ sx: {
                                                        marginLeft: 'auto',
                                                    } }, { children: _jsx(IconButton, __assign({ onClick: function () { return setShowCB(!showCB); } }, { children: _jsx(TPSvgIcon, { id: 'contractors-billing-caret-icon', "data-testid": 'contractors-billing-caret-icon', resourceName: showCB
                                                                ? 'up-arrow-strong-icon'
                                                                : 'down-arrow-strong-icon', width: 24, height: 24 }) })) })))] })), (isMobile() ? showCB : true) && (_jsx(Box, __assign({ mt: 4, sx: {
                                                order: { xs: 3, md: 3 },
                                            } }, { children: _jsx(ContractorsBilling, { ContractorsOnBillingMetrics: contractorOnBiilingData, onDrillDownData: function (data) {
                                                    setOperationaldrillDownData(data);
                                                }, setOpenDrillDown: function (val) { return setOpenDrillDown(val); }, isLoading: isLoading }) })))] })) })), _jsx("br", {}), (isMobile() ? showCB : true) && (_jsxs(Grid, __assign({ item: true, sx: {
                                    p: 2,
                                    backgroundColor: colorCodes.backgroundColors.white,
                                } }, { children: [_jsxs(Box, __assign({ sx: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '50px',
                                        } }, { children: [_jsx(Typography, __assign({ id: 'placed-positions-title', "data-testid": 'placed-positions-title', variant: 'h6', component: 'h2' }, { children: "Placed Positions" })), _jsx(Typography, __assign({ sx: { ml: 1 } }, { children: "(12 Months Trend)" })), _jsx(Box, __assign({ id: 'info-icon-4', "data-testid": 'info-icon-4', sx: { cursor: 'pointer', marginLeft: 'auto' }, onClick: function () { return handleInfoModal('placed-positions'); } }, { children: _jsx(TPSvgIcon, { resourceName: 'info-icon', id: 'info-icon', "data-testid": 'info-icon' }) }))] })), _jsx(Box, __assign({ mt: 4 }, { children: _jsx(PlacedPositions, { placedPositionsMetrics: PlacedPositionsData, isLoading: isLoading }) }))] })))] }))] })), openInfoModal && (_jsx(CustomModal, { id: 'operational-metrics-info-modal', "data-testid": 'operational-metrics-info-modal', title: modalHeading, open: openInfoModal, PaperProps: {
                    sx: { width: '100%', maxWidth: '35rem' },
                }, onClose: function () { return setOpenInfoModal(false); }, children: _jsx(InfoModal, { infoModalData: infoModalData, setOpenInfoModal: setOpenInfoModal }) }))] }));
};
export default OperationalMetrics;
