var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Box, Divider, Grid, Link, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from '@mui/material';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { CustomScrollContainer, placementsStyles, StyledTableCell, StyledTableRow, } from '../../pages/placements/Placements.styled';
import CustomPagination from '../../../../tandym-web-common/src/shared/pagination/CustomPagination';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { formatDate } from '../../shared/utilities/utilities';
import { overViewStyles, liveContractorDrillDownStyles, } from '../../pages/Home/Overview/OverViewStyles';
import bannerImage from '../../images/openPositionsIIconCarouselBackgroundImg.png';
import { colorCodes } from '../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterDrawer from '../../shared/filter/FilterDrawer';
import { SectionTitle, solutions } from '../../shared/utilities/enumValues';
import { useMediaQuery } from '@mui/material';
var LiveContractorDrillDown = function (_a) {
    var onBackClick = _a.onBackClick, title = _a.title, setDrillDownTitle = _a.setDrillDownTitle, drillDownData = _a.drillDownData, consultingSolutions = _a.consultingSolutions, missionCoreSolutions = _a.missionCoreSolutions, totalLiveContractors = _a.totalLiveContractors, liveContractorData = _a.liveContractorData;
    var rowsPerPage = 10;
    var _b = useState(0), page = _b[0], setPage = _b[1];
    var _c = useState([]), sortedData = _c[0], setSortedData = _c[1];
    var _d = useState({}), sortOrder = _d[0], setSortOrder = _d[1];
    var _e = useState(false), showFilter = _e[0], setShowFilter = _e[1];
    var _f = useState(null), startDateFrom = _f[0], setStartDateFrom = _f[1];
    var _g = useState(null), startDateTo = _g[0], setStartDateTo = _g[1];
    var _h = useState(null), endDateFrom = _h[0], setEndDateFrom = _h[1];
    var _j = useState(null), endDateTo = _j[0], setEndDateTo = _j[1];
    var _k = useState([]), allCandidateNames = _k[0], setAllCandidateNames = _k[1];
    var _l = useState([]), allClients = _l[0], setAllClients = _l[1];
    var _m = useState([]), allJobTitles = _m[0], setAllJobTitles = _m[1];
    var _o = useState([]), allConsultants = _o[0], setAllConsultants = _o[1];
    var _p = useState(''), selectedJobTitle = _p[0], setSelectedJobTitle = _p[1];
    var _q = useState(''), selectedCandidateName = _q[0], setSelectedCandidateName = _q[1];
    var _r = useState(''), selectedClient = _r[0], setSelectedClient = _r[1];
    var _s = useState(''), selectedConsultant = _s[0], setSelectedConsultant = _s[1];
    var _t = useState([]), multiFilter = _t[0], setMultiFilter = _t[1];
    var _u = useState([]), initialDrillDownData = _u[0], setInitialDrillDownData = _u[1];
    var isScreenWidth900 = useMediaQuery('(min-width:900px)');
    useEffect(function () {
        if (drillDownData && drillDownData.length > 0) {
            var consolidatedData = drillDownData
                .filter(function (item) {
                return Array.isArray(item.drillDownData) && item.drillDownData.length > 0;
            })
                .flatMap(function (item) {
                return item.drillDownData.map(function (drillDownItem) { return (__assign(__assign({}, drillDownItem), { name: item.name, emptyColumn: '' })); });
            });
            setMultiFilter([title]);
            setSortedData(consolidatedData);
            setInitialDrillDownData(consolidatedData);
            var uniqueCandidates_1 = new Set();
            var uniqueClients_1 = new Set();
            var uniqueJobTitles_1 = new Set();
            var uniqueConsultants_1 = new Set();
            consolidatedData.forEach(function (item) {
                uniqueCandidates_1.add(item.candidate);
                uniqueClients_1.add(item.client);
                uniqueJobTitles_1.add(item.jobTitle);
                uniqueConsultants_1.add(item.consultant);
            });
            setAllCandidateNames(Array.from(uniqueCandidates_1).map(function (name) { return name; }));
            setAllClients(Array.from(uniqueClients_1).map(function (name) { return name; }));
            setAllJobTitles(Array.from(uniqueJobTitles_1).map(function (name) { return name; }));
            setAllConsultants(Array.from(uniqueConsultants_1).map(function (name) { return name; }));
        }
    }, [drillDownData]);
    var columnKeyMap = useMemo(function () { return ({
        Candidate: 'candidate',
        Client: 'client',
        Start: 'startDate',
        End: 'endDate',
        'Job Title': 'jobTitle',
        Consultant: 'consultant',
        '': 'emptyColumn', // Add an empty column key
    }); }, []);
    var handleSort = useCallback(function (columnDisplayName) {
        var columnKey = columnKeyMap[columnDisplayName];
        var isAsc = sortOrder[columnKey] === 'asc';
        var order = isAsc ? 'desc' : 'asc';
        setSortOrder(function (prevSortOrder) {
            var _a;
            return (__assign(__assign({}, prevSortOrder), (_a = {}, _a[columnKey] = order, _a)));
        });
        var sorted = __spreadArray([], sortedData, true).sort(function (a, b) {
            var aValue = a[columnKey];
            var bValue = b[columnKey];
            if (columnKey === 'startDate' || columnKey === 'endDate') {
                var dateA = new Date(aValue);
                var dateB = new Date(bValue);
                return order === 'asc'
                    ? dateA.getTime() - dateB.getTime()
                    : dateB.getTime() - dateA.getTime();
            }
            if (aValue == null && bValue == null)
                return 0;
            if (aValue == null)
                return order === 'asc' ? 1 : -1;
            if (bValue == null)
                return order === 'asc' ? -1 : 1;
            return aValue < bValue
                ? order === 'asc'
                    ? -1
                    : 1
                : order === 'asc'
                    ? 1
                    : -1;
        });
        setSortedData(sorted);
    }, [columnKeyMap, sortOrder, sortedData]);
    var filterHandler = useCallback(function (name) {
        setMultiFilter(function (prevFilters) {
            var updatedFilters = prevFilters.includes(name)
                ? prevFilters.filter(function (filter) { return filter !== name; })
                : __spreadArray([name], prevFilters, true);
            var combinedData = __spreadArray(__spreadArray([], consultingSolutions, true), missionCoreSolutions, true);
            var filteredData = combinedData
                .filter(function (item) { return updatedFilters.includes(item.name); })
                .map(function (item) { return item.drillDownData; })
                .flat();
            var totalData = combinedData
                .map(function (item) { return item.drillDownData; })
                .flat();
            var constValues = [
                SectionTitle.MyLiveContractors,
                'Total My Live Contractors',
                solutions.ConsultingSolutions,
                solutions.MissionCoreSolutions,
            ];
            var myLiveContractors = constValues.some(function (value) {
                return updatedFilters.includes(value);
            });
            var count = myLiveContractors ? 1 : 0;
            var selectedChips = updatedFilters.length > count ? filteredData : totalData;
            setSortedData(selectedChips);
            setPage(0);
            setDrillDownTitle(updatedFilters.length > 0 ? name : '');
            return updatedFilters;
        });
    }, [consultingSolutions, missionCoreSolutions, setDrillDownTitle]);
    var isCSAvailable = (consultingSolutions === null || consultingSolutions === void 0 ? void 0 : consultingSolutions.length) > 0;
    var isMCSAvailable = (missionCoreSolutions === null || missionCoreSolutions === void 0 ? void 0 : missionCoreSolutions.length) > 0;
    var handleFilterApply = function () {
        var filteredData = initialDrillDownData;
        if (selectedCandidateName) {
            filteredData = filteredData.filter(function (item) { return item.candidate === selectedCandidateName; });
        }
        if (selectedClient) {
            filteredData = filteredData.filter(function (item) { return item.client === selectedClient; });
        }
        if (selectedJobTitle) {
            filteredData = filteredData.filter(function (item) { return item.jobTitle === selectedJobTitle; });
        }
        if (selectedConsultant) {
            filteredData = filteredData.filter(function (item) { return item.consultant === selectedConsultant; });
        }
        if (startDateFrom) {
            filteredData = filteredData.filter(function (item) { return item.startDate && new Date(item.startDate) >= startDateFrom; });
        }
        if (startDateTo) {
            filteredData = filteredData.filter(function (item) { return item.startDate && new Date(item.startDate) <= startDateTo; });
        }
        if (endDateFrom) {
            filteredData = filteredData.filter(function (item) { return item.endDate && new Date(item.endDate) >= endDateFrom; });
        }
        if (endDateTo) {
            filteredData = filteredData.filter(function (item) { return item.endDate && new Date(item.endDate) <= endDateTo; });
        }
        setSortedData(filteredData);
        setShowFilter(false);
        setPage(0);
    };
    return (_jsxs(Grid, __assign({ sx: { mx: '28px', background: '#fff' }, id: 'live-contractor-drilldown' }, { children: [_jsx(Grid, __assign({ id: 'live-contractor-drilldown-banner', sx: [
                    overViewStyles.image,
                    {
                        backgroundImage: isScreenWidth900 ? "url(".concat(bannerImage, ")") : '',
                        width: '100%',
                        borderBottom: '1px solid #EAECF0',
                    },
                ] }, { children: _jsxs(Grid, __assign({ container: true, sx: {
                        padding: '20px',
                    } }, { children: [_jsxs(Grid, __assign({ item: true, sm: 3, md: 2, lg: 3 }, { children: [_jsxs(Box, __assign({ id: 'section-1' }, { children: [_jsx(Typography, __assign({ fontSize: fontSizeDynamic(14), sx: { mb: '10px' } }, { children: "Note - Click this back arrow to return to the home page." })), _jsx(Link, __assign({ underline: 'none', onClick: onBackClick, "data-testid": 'back-click', id: 'back-click', sx: { cursor: 'pointer' } }, { children: _jsx(TPSvgIcon, { resourceName: 'arrow-icon-left' }) })), _jsx(TPSvgIcon, { resourceName: 'small-vertical-line-icon' }), _jsx(Typography, __assign({ sx: {
                                                display: 'inline-block',
                                                position: 'relative',
                                                bottom: '3px',
                                            }, fontSize: fontSizeDynamic(24), "data-testid": 'live-contractor-drill-down-title' }, { children: "My Live Contractor" }))] })), _jsxs(Box, __assign({ mt: '24px' }, { children: [_jsx(Typography, __assign({ fontSize: fontSizeDynamic(12), id: 'live-contractor-drill-down-subtitle', "data-testid": 'live-contractor-drill-down-subtitle', sx: liveContractorDrillDownStyles.functionalGroupName }, { children: "Total live contrators" })), _jsx(Typography, __assign({ fontSize: fontSizeDynamic(40), sx: liveContractorDrillDownStyles.totalValue }, { children: totalLiveContractors }))] }))] })), _jsx(Grid, __assign({ item: true, sm: 2, md: 1, lg: 1, sx: { display: 'inline-flex' } }, { children: _jsx(Divider, { sx: liveContractorDrillDownStyles.divider, orientation: 'vertical', variant: 'middle', flexItem: true }) })), _jsx(Grid, __assign({ item: true, sm: 7, md: 7, lg: 4 }, { children: _jsxs(Box, __assign({ id: 'section-2', className: 'drill-down-header', sx: {
                                    paddingTop: '8px',
                                } }, { children: [isMCSAvailable && (_jsxs(Box, __assign({ className: 'mission-core-solutions-header', sx: {
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            gap: '1rem',
                                        } }, { children: [_jsxs(Box, __assign({ className: 'functional-group-text' }, { children: [_jsx(Typography, __assign({ fontSize: fontSizeDynamic(12), "data-testid": 'mission-core-solutions-text', id: 'mission-core-solutions-text', sx: liveContractorDrillDownStyles.functionalGroupName }, { children: "Mission Core Solutions" })), _jsx(Typography, __assign({ fontSize: fontSizeDynamic(24), id: 'mission-core-solutions-count', "data-testid": 'mission-core-solutions-count' }, { children: liveContractorData === null || liveContractorData === void 0 ? void 0 : liveContractorData.missionCoreTotal.toString().padStart(2, '0') }))] })), _jsx(Box, __assign({ sx: liveContractorDrillDownStyles.filterBoxes }, { children: missionCoreSolutions === null || missionCoreSolutions === void 0 ? void 0 : missionCoreSolutions.map(function (item) {
                                                    var isTitleMatch = multiFilter.find(function (value) { return value === item.name; });
                                                    return (_jsxs(Box, __assign({ className: 'mission-core-solutions-circle', fontSize: fontSizeDynamic(14), id: "live-contractor-mission-core-solutions-".concat(item.name), "data-testid": "live-contractor-mission-core-solutions-".concat(item.name), sx: __assign(__assign({}, liveContractorDrillDownStyles.functionalGroupCircle), { backgroundColor: isTitleMatch
                                                                ? colorCodes.iconBackgroundColor.royalBlue
                                                                : '', color: isTitleMatch
                                                                ? colorCodes.backgroundColors.white
                                                                : '', borderColor: isTitleMatch
                                                                ? colorCodes.borderColor.QuillGray
                                                                : '', cursor: 'pointer' }), onClick: function () { return filterHandler(item.name); } }, { children: [item.name, ' ', _jsx("span", __assign({ style: {
                                                                    color: isTitleMatch
                                                                        ? colorCodes.backgroundColors.white
                                                                        : colorCodes.iconBackgroundColor.royalBlue,
                                                                } }, { children: item.positionCount }))] }), item.id));
                                                }) }))] }))), isCSAvailable && (_jsxs(Box, __assign({ className: 'consulting-solutions-header', sx: liveContractorDrillDownStyles.consultingSolutionsHeader }, { children: [_jsxs(Box, __assign({ className: 'functional-group-text' }, { children: [_jsx(Typography, __assign({ fontSize: fontSizeDynamic(12), "data-testid": 'consulting-solutions-text', id: 'consulting-solutions-text', sx: liveContractorDrillDownStyles.functionalGroupName }, { children: "Consulting Solutions" })), _jsx(Typography, __assign({ fontSize: fontSizeDynamic(24), id: 'consulting-solutions-count', "data-testid": 'consulting-solutions-count' }, { children: liveContractorData === null || liveContractorData === void 0 ? void 0 : liveContractorData.consultantTotal.toString().padStart(2, '0') }))] })), _jsx(Box, __assign({ sx: liveContractorDrillDownStyles.filterBoxes }, { children: consultingSolutions === null || consultingSolutions === void 0 ? void 0 : consultingSolutions.map(function (item) {
                                                    var isTitleMatch = multiFilter.find(function (value) { return value === item.name; });
                                                    return (_jsxs(Box, __assign({ className: 'consulting-solutions-circle', id: "live-contractor-consulting-solutions-".concat(item.name), "data-testid": "live-contractor-consulting-solutions-".concat(item.name), sx: __assign(__assign({}, liveContractorDrillDownStyles.functionalGroupCircle), { backgroundColor: isTitleMatch
                                                                ? colorCodes.iconBackgroundColor.royalBlue
                                                                : '', color: isTitleMatch
                                                                ? colorCodes.backgroundColors.white
                                                                : '', borderColor: isTitleMatch
                                                                ? colorCodes.borderColor.QuillGray
                                                                : '', cursor: 'pointer' }), onClick: function () { return filterHandler(item.name); }, fontSize: fontSizeDynamic(14) }, { children: [item.name, ' ', _jsx("span", __assign({ style: {
                                                                    color: isTitleMatch
                                                                        ? colorCodes.backgroundColors.white
                                                                        : colorCodes.iconBackgroundColor.royalBlue,
                                                                } }, { children: item.positionCount }))] }), item.id));
                                                }) }))] })))] })) }))] })) })), _jsxs(Grid, { children: [_jsx(TableContainer, __assign({ component: Paper, sx: { maxHeight: 440, overflowX: 'auto' } }, { children: _jsx(CustomScrollContainer, { children: _jsxs(Table, __assign({ stickyHeader: true }, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: [
                                                'Candidate',
                                                'Client',
                                                'Start',
                                                'End',
                                                'Job Title',
                                                'Consultant',
                                                '',
                                            ].map(function (columnDisplayName) {
                                                var columnKey = columnKeyMap[columnDisplayName];
                                                return (_jsx(StyledTableCell, { children: columnKey === 'emptyColumn' ? (_jsx(FilterAltOutlinedIcon, { sx: { color: '#2A70EA', cursor: 'pointer' }, id: 'table-header-filter', "data-testid": 'table-header-filter', onClick: function () { return setShowFilter(true); } })) : (_jsxs(Stack, __assign({ id: "table-header-".concat(columnDisplayName), "data-testid": "table-header-".concat(columnDisplayName), direction: 'row', spacing: 1, alignItems: 'center', sx: { cursor: 'pointer' }, onClick: function () { return handleSort(columnDisplayName); } }, { children: [_jsx(Typography, { children: columnDisplayName.toUpperCase() }), _jsx(TPSvgIcon, { resourceName: 'sort-icon', id: "sort-icon-".concat(columnDisplayName), "data-testid": "sort-icon-".concat(columnDisplayName) })] }))) }, columnDisplayName));
                                            }) }) }), _jsx(TableBody, { children: (sortedData === null || sortedData === void 0 ? void 0 : sortedData.length) > 0 ? (sortedData
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map(function (item, index) { return (_jsxs(StyledTableRow, { children: [_jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: item.candidate })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: item.client })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: item.startDate ? formatDate(item.startDate) : 'N/A' })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: item.endDate ? formatDate(item.endDate) : 'N/A' })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: item.jobTitle })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: item.consultant })), _jsx(TableCell, __assign({ sx: { minWidth: 'auto' } }, { children: item.emptyColumn }))] }, 'table-data-' + index)); })) : (_jsx(StyledTableRow, { children: _jsx(TableCell, __assign({ colSpan: 7, align: 'center' }, { children: "No data found" })) })) })] })) }) })), _jsx(CustomPagination, { count: sortedData.length, page: page, rowsPerPage: rowsPerPage, onPageChange: function (_, newPage) { return setPage(newPage); } })] }), showFilter && (_jsx(FilterDrawer, { title: SectionTitle.MyLiveContractors, open: showFilter, onClose: function () { return setShowFilter(false); }, fields: [
                    {
                        type: 'dropdown',
                        label: 'Candidate Name',
                        options: allCandidateNames,
                        selectedOption: selectedCandidateName,
                        onChange: function (selectedOption) {
                            return setSelectedCandidateName(selectedOption);
                        },
                    },
                    {
                        type: 'dropdown',
                        label: 'Client',
                        options: allClients,
                        selectedOption: selectedClient,
                        onChange: function (selectedOption) {
                            return setSelectedClient(selectedOption);
                        },
                    },
                    {
                        type: 'dropdown',
                        label: 'Job Title',
                        options: allJobTitles,
                        selectedOption: selectedJobTitle,
                        onChange: function (selectedOption) {
                            return setSelectedJobTitle(selectedOption);
                        },
                    },
                    {
                        type: 'dropdown',
                        label: 'Consultant',
                        options: allConsultants,
                        selectedOption: selectedConsultant,
                        onChange: function (selectedOption) {
                            return setSelectedConsultant(selectedOption);
                        },
                    },
                ], onApply: handleFilterApply, startDateFrom: startDateFrom, setStartDateFrom: setStartDateFrom, startDateTo: startDateTo, setStartDateTo: setStartDateTo, endDateFrom: endDateFrom, setEndDateFrom: setEndDateFrom, endDateTo: endDateTo, setEndDateTo: setEndDateTo }))] })));
};
export default LiveContractorDrillDown;
