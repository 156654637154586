var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { isMobile } from '../../../../tandym-web-common/src/shared/utilities/isMobileEntitlementFlag';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from '@mui/material';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var getColor = function (value, rowType) {
    var value1 = Number(value);
    if (rowType === 'deltaFromTandymAvg' && typeof value1 === 'number') {
        if (value1 > 0.0)
            return '#FF8A8A';
        if (value1 <= 0.0)
            return '#D5ED9F';
    }
    return '#ffffff';
};
var RecruitmentTimelineSteps = function (props) {
    var recruitmentTimelineSteps = props.recruitmentTimelineSteps, clientName = props.clientName;
    var tableKeyNameValidation = function (keyName) {
        switch (keyName) {
            case 'deltaFromTandymAvg':
                return 'Delta from Tandym Avg';
            case 'tandymGTMAvg':
                return 'Tandym Functional Skill Average';
        }
    };
    var tabelHeaderTextAndBackgroundColor = function (label, header) {
        if (label === 'Total' && header === 'header')
            return '#444444';
        if (label === 'Total' && header === 'headertext')
            return 'white';
        if (['Search', 'Submit', 'Accept'].includes(label) && header === 'header')
            return '#2b6fea';
        if (['Search', 'Submit', 'Accept'].includes(label) &&
            header === 'headertext')
            return 'white';
        if (['Interview', 'Offer', 'Start'].includes(label) && header === 'header')
            return '#a6a6a6';
        if (['Interview', 'Offer', 'Start'].includes(label) &&
            header === 'headertext')
            return 'black';
        return '';
    };
    var smallScreen = function () {
        var _a, _b;
        return (_jsx(Box, __assign({ sx: {
                backgroundColor: '#000000',
                p: 5,
                pt: 0,
                alignItems: 'center',
            } }, { children: _jsx(TableContainer, __assign({ component: Paper }, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: {
                                            backgroundColor: '#444444',
                                            color: '#ffffff',
                                            fontWeight: 'bold',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 1,
                                            border: 'none',
                                            fontSize: fontSizeDynamic(12),
                                        } }, { children: "Recruitment Step" })), (_a = recruitmentTimelineSteps === null || recruitmentTimelineSteps === void 0 ? void 0 : recruitmentTimelineSteps.kpi) === null || _a === void 0 ? void 0 : _a.map(function (label, index) { return (_jsx(TableCell, __assign({ sx: {
                                            backgroundColor: tabelHeaderTextAndBackgroundColor(label, 'header'),
                                            color: tabelHeaderTextAndBackgroundColor(label, 'headertext'),
                                            fontWeight: 'bold',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 1,
                                            whiteSpace: 'normal',
                                            wordWrap: 'break-word',
                                            overflowWrap: 'break-word',
                                            borderBottom: 'none',
                                            borderRight: '5px solid #000',
                                            fontSize: fontSizeDynamic(12),
                                        } }, { children: label }), index)); })] }) }), _jsx(TableBody, { children: recruitmentTimelineSteps &&
                                ((_b = Object.entries(recruitmentTimelineSteps)) === null || _b === void 0 ? void 0 : _b.map(function (_a, rowIndex) {
                                    var key = _a[0], values = _a[1];
                                    if (key === 'kpi')
                                        return null;
                                    return (_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: {
                                                    backgroundColor: '#000000',
                                                    color: '#ffffff',
                                                    fontWeight: 'bold',
                                                    border: 'none',
                                                    fontSize: fontSizeDynamic(12),
                                                } }, { children: key === 'selectedClient'
                                                    ? clientName
                                                    : tableKeyNameValidation(key) })), recruitmentTimelineSteps === null || recruitmentTimelineSteps === void 0 ? void 0 : recruitmentTimelineSteps.kpi.map(function (label, index) { return (_jsx(TableCell, __assign({ sx: {
                                                    backgroundColor: getColor(values[label], key),
                                                    color: '#000000',
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word',
                                                    overflowWrap: 'break-word',
                                                    borderBottom: 'none',
                                                    borderRight: index < recruitmentTimelineSteps.kpi.length
                                                        ? '5px solid #000'
                                                        : 'none',
                                                    fontSize: fontSizeDynamic(12),
                                                } }, { children: values[label] }), index)); })] }, rowIndex));
                                })) })] }) })) })));
    };
    var largeScreenResolution = function () {
        var _a, _b;
        return (_jsxs(Box, __assign({ sx: {
                display: 'grid',
                gap: '2px',
                backgroundColor: '#000000',
                padding: '20px',
                color: '#ffffff',
            } }, { children: [_jsxs(Grid, __assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, __assign({ item: true, xs: 2, sx: {
                                backgroundColor: '#444444',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '60px',
                            } }, { children: _jsx(Typography, __assign({ variant: 'subtitle1', style: { fontWeight: 'bold', color: '#ffffff' } }, { children: "Recruitment Step" })) })), recruitmentTimelineSteps &&
                            ((_a = recruitmentTimelineSteps === null || recruitmentTimelineSteps === void 0 ? void 0 : recruitmentTimelineSteps.kpi) === null || _a === void 0 ? void 0 : _a.map(function (label, index) { return (_jsx(Grid, __assign({ item: true, xs: index === 6 ? 1.5 : 1, sx: {
                                    backgroundColor: tabelHeaderTextAndBackgroundColor(label, 'header'),
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '60px',
                                    marginRight: index === 5 ? '10px' : '5px',
                                } }, { children: _jsx(Typography, __assign({ variant: 'subtitle1', style: {
                                        fontWeight: 'bold',
                                        color: tabelHeaderTextAndBackgroundColor(label, 'headertext'),
                                    } }, { children: label })) }), index)); }))] })), recruitmentTimelineSteps &&
                    ((_b = Object.entries(recruitmentTimelineSteps)) === null || _b === void 0 ? void 0 : _b.map(function (_a, rowIndex) {
                        var key = _a[0], values = _a[1];
                        if (key === 'kpi')
                            return null;
                        return (_jsxs(React.Fragment, { children: [_jsxs(Grid, __assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, __assign({ item: true, xs: 2, sx: {
                                                backgroundColor: '#000000',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '60px',
                                            } }, { children: _jsx(Typography, __assign({ variant: 'subtitle1', style: {
                                                    fontWeight: 'bold',
                                                    color: '#ffffff',
                                                } }, { children: key === 'selectedClient'
                                                    ? clientName
                                                    : tableKeyNameValidation(key) })) })), recruitmentTimelineSteps === null || recruitmentTimelineSteps === void 0 ? void 0 : recruitmentTimelineSteps.kpi.map(function (label, index) { return (_jsx(Grid, __assign({ item: true, xs: index === 6 ? 1.5 : 1, sx: {
                                                backgroundColor: getColor(values[label], key),
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '60px',
                                                marginRight: index === 5 ? '10px' : '5px',
                                            } }, { children: _jsx(Typography, __assign({ variant: 'subtitle1', style: { fontWeight: 'bold', color: '#000000' } }, { children: values[label] })) }), index)); })] })), key === 'Delta from Tandym Avg' && (_jsx(Box, { sx: {
                                        borderBottom: '1px solid #ffffff',
                                        marginTop: '5px',
                                        marginBottom: '22px',
                                    } }))] }, rowIndex));
                    }))] })));
    };
    return _jsx(_Fragment, { children: isMobile() ? smallScreen() : largeScreenResolution() });
};
export default RecruitmentTimelineSteps;
